<template>
  <div class="container __problemas-container my-3" data-aos="fade-down">
    <div class="row align-items-center">
      <!--        columna prncipal-->
      <div class="col-12 col-lg-4 order-1">
        <div class="mb-5 d-md-block d-none">
          <h2>
            <span style="color: #14993c"> Español </span>
          </h2>
          <div class="wet-form">
            <input  class="form-control form-control-sm" type="text" v-model="preTitleES"/>
          </div>
          <div class="wet-form">
            <input  class="form-control" type="text" v-model="blackTitleES">
          </div>
          <div class="wet-form">
            <input class="form-control" style="color: #14993c" type="text" v-model="greenTitleES">
          </div>
        </div>
        <img
          src="@/assets/img/hombre_pensativo.webp"
          class="img-fluid d-none d-md-block responsive-img"
          alt="Imagen de hombre pensando en desafíos"
          loading="lazy"
        />
        <img
          src="@/assets/img/hombre_pensativo_mobile.webp"
          class="img-fluid d-block d-md-none"
          alt="Imagen de hombre pensando en desafíos version movil"
          loading="lazy"
        />
      </div>
      <!--        columna secundaria (features)-->
      <div class="col-12 col-lg-8 order-1 order-md-2">
        <div class="row">
          <div class="col-12 col-md-6" v-for="(feature, i) in featuresES" :key="feature.id">
            <div class="d-flex align-items-center my-4 mx-2">
              <div class="border-card">
                <img
                  :src="require(`../../assets/${feature.img}`)"
                  :alt="`Ícono desafío numero ${i + 1}`"
                  class="card-image "
                  loading="lazy"
                />
              </div>
              <div class="card-content shadow-sm wet-form">
                <textarea class="form-control" type="text" rows="5" v-model="feature.text"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success btn-lg mt-3 mb-4" @click="syncContent('ES')">Guardar</button>
    <hr class="pill my-4">
    <div class="row align-items-center">
      <!--        columna prncipal-->
      <div class="col-12 col-lg-4 order-1">
        <div class="mb-5 d-md-block d-none">
          <h2>
            <span style="color: #14993c"> English </span>
          </h2>
          <div class="wet-form">
            <input  class="form-control form-control-sm" v-model="preTitleEN"/>
          </div>
          <div class="wet-form">
            <input  class="form-control" type="text" v-model="blackTitleEN">
          </div>
          <div class="wet-form">
            <input class="form-control" style="color: #14993c" type="text" v-model="greenTitleEN">
          </div>
        </div>
        <img
          src="@/assets/img/hombre_pensativo.webp"
          class="img-fluid d-none d-md-block responsive-img"
          alt="Imagen de hombre pensando en desafíos"
          loading="lazy"
        />
        <img
          src="@/assets/img/hombre_pensativo_mobile.webp"
          class="img-fluid d-block d-md-none"
          alt="Imagen de hombre pensando en desafíos version movil"
          loading="lazy"
        />
      </div>
      <!--        columna secundaria (features)-->
      <div class="col-12 col-lg-8 order-1 order-md-2">
        <div class="row">
          <div class="col-12 col-md-6" v-for="(feature, i) in featuresEN" :key="feature.id">
            <div class="d-flex align-items-center my-4 mx-2">
              <div class="border-card">
                <img
                  :src="require(`../../assets/${feature.img}`)"
                  :alt="`Ícono desafío numero ${i + 1}`"
                  class="card-image "
                  loading="lazy"
                />
              </div>
              <div class="card-content shadow-sm wet-form">
                <textarea class="form-control" type="text" rows="5" v-model="feature.text"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="btn btn-success btn-lg mt-3" @click="syncContent('EN')">Save</button>
  </div>
</template>

<script>
import { getSection, putSection } from '../../helpers/contenido'
import store from '../../store';

export default {
  name: "Problemas",
  data() {
    return {
        blackTitleES: "¿Te identificas con",
        greenTitleES: "estos desafíos?",
        preTitleES: "Estimado Empresario",
        featuresES: [
          {
            id: 1,
            img: 'img/icono_problemas_1.svg',
            text: 'Tener un modelo de negocio que permita crecer y competir en tiempos de grandes cambios.'
          },
          {
            id: 2,
            img: 'img/icono_problemas_5.svg',
            text: 'Tener objetivos claros para todos.'
          },
          {
            id: 3,
            img: 'img/icono_problemas_2.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 4,
            img: 'img/icono_problemas_6.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 5,
            img: 'img/icono_problemas_3.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 6,
            img: 'img/icono_problemas_7.svg',
            text:' Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 7,
            img: 'img/icono_problemas_4.svg',
            text:' Conocer el valor de tu empresa y sentirte seguro para abordar rondas de inversión.'
          },
          {
            id: 8,
            img: 'img/icono_problemas_8.svg',
            text: 'Lograr que las nuevas innovaciones que piensas se ejecuten a través de proyectos que las hagan realidad.'
        }],


        blackTitleEN: "¿Te identificas con",
        greenTitleEN: "estos desafíos?",
        preTitleEN: "Estimado Empresario",
        featuresEN: [
          {
            id: 1,
            img: 'img/icono_problemas_1.svg',
            text: 'Tener un modelo de negocio que permita crecer y competir en tiempos de grandes cambios.'
          },
          {
            id: 2,
            img: 'img/icono_problemas_5.svg',
            text: 'Tener objetivos claros para todos.'
          },
          {
            id: 3,
            img: 'img/icono_problemas_2.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 4,
            img: 'img/icono_problemas_6.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 5,
            img: 'img/icono_problemas_3.svg',
            text: 'Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 6,
            img: 'img/icono_problemas_7.svg',
            text:' Tomar acción rápida frente a problemas y oportunidades de mejora.'
          },
          {
            id: 7,
            img: 'img/icono_problemas_4.svg',
            text:' Conocer el valor de tu empresa y sentirte seguro para abordar rondas de inversión.'
          },
          {
            id: 8,
            img: 'img/icono_problemas_8.svg',
            text: 'Lograr que las nuevas innovaciones que piensas se ejecuten a través de proyectos que las hagan realidad.'
        }],
    };
  },
  methods: {
    fetchData() {
         if (store.state.sections.challenges) {
      this.data = {...store.state.sections.challenges.components}; }
    },
    async fetchContent() {
      const section = await getSection("challenges");
      const sectionEs = section.filter( (section) => section.language == "ES"  )
      const sectionEn = section.filter( (section) => section.language == "EN"  )

      this.sectionEs = sectionEs
      this.sectionEn = sectionEn

      this.preTitleES = sectionEs[0].components.preTitle
      this.blackTitleES = sectionEs[0].components.blackTitle
      this.greenTitleES = sectionEs[0].components.greenTitle
      this.featuresES = sectionEs[0].components.features
      this.preTitleEN = sectionEn[0].components.preTitle
      this.blackTitleEN = sectionEn[0].components.blackTitle
      this.greenTitleEN = sectionEn[0].components.greenTitle
      this.featuresEN = sectionEn[0].components.features
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
            id: this.sectionEs[0]._id,
            section: "challenges",
            components: {
                preTitle: this.preTitleES,
                blackTitle: this.blackTitleES,
                greenTitle: this.greenTitleES,
                features: this.featuresES
            },
            language: "ES"
        }
       await putSection(words);
      } else {
        const words = {
            id: this.sectionEn[0]._id,
            section: "challenges",
            components: {
                preTitle: this.preTitleEN,
                blackTitle: this.blackTitleEN,
                greenTitle: this.greenTitleEN,
                features: this.featuresEN
            },
            language: "EN"

        }
        await putSection(words);
      }
    }

  },
  watch: {
  },

  created() {
    this.fetchData();
    this.fetchContent();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->


<style scoped lang="scss">

.__problemas-container {
  .border-card {
  }

  .card-image {
    margin-right: -3rem;
    width: 100px;
  }

  .card-content {
    padding: 1rem 3.2rem;
    display: flex;
    align-items: center;
    width: 100%;
  }

  p {
    font-size: 0.813rem !important;
  }

  h3 {
    font-family: InterMedium !important;
    font-size: 1.25rem !important;
  }
}

hr.pill {
  height:0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}



@media (min-width: 762px) {
  .__problemas-container {
    .card-content {
      height: 150px;
      padding-right: 15px;

    }

    .responsive-img {
      width: 50%;
    }
  }
}

@media(min-width: 1000px) {
  .__problemas-container {
    .card-content {
      min-height: 100px;
    }

    .responsive-img {
      width: 100%;
    }
  }
}

@media (min-width: 1200px) {
  .__problemas-container {
    .card-content {
      min-height: 120px;

      p {
        font-size: 1rem !important;
      }
    }

    .card-image {
      width: auto;
    }
  }
}

</style>
